<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <!-- <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue" v-else>
            <el-button class="fc-0075F6" slot="append" type="primary" icon="el-icon-search" @click="submitSearch">
            </el-button>
          </el-input> -->
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="newWealColumns" height="100%"
        :border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 每日福利 新人活动 道具 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="15vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="充值金额" prop="rcgAmt" :rules="{required: true, message: '充值金额名称不能为空', trigger: 'blur'}">
          <el-input v-model="ruleForm.rcgAmt"></el-input>
        </el-form-item>
        <el-form-item label="赠送金额" prop="rwrdAmt" :rules="{required: false, message: '赠送金额不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.rwrdAmt"></el-input>
        </el-form-item>
        <el-form-item label="转盘次数" prop="luckNum" :rules="{required: false, message: '赠送金额不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.luckNum"></el-input>
        </el-form-item>
        <el-form-item label="活动类型" prop="rcgType" :rules="{required: true, message: '请选择活动类型', trigger: 'change'}">
          <el-select v-model="ruleForm.rcgType" placeholder="请选择">
            <el-option v-for="item in rcgTypeList" :key="item.value" :label="item.key" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动时间" required>
          <el-col :span="11">
            <el-form-item prop="startDate" :rules="{required: true, message: '请选择开始时间', trigger: 'change'}">
              <el-date-picker type="datetime" valueFormat="yyyy-MM-dd HH:mm:ss" placeholder="开始时间"
                v-model="ruleForm.startDate" default-time="00:00:00" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="ta-c" :span="2">-</el-col>
          <el-col :span="11">
            <el-form-item prop="endDate" :rules="{required: true, message: '请选择结束时间', trigger: 'change'}">
              <el-date-picker type="datetime" valueFormat="yyyy-MM-dd HH:mm:ss" placeholder="结束时间"
                v-model="ruleForm.endDate" default-time="23:59:59" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'newcomer',
  mixins: [indexMixin],
  components: {
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'rcgTypeList': state => state.dictList ? state.dictList.rcg_type : [],  //活动类型
    })
  },
  data () {
    return {
      retrieveForm: {
        exchangeName: '',
        propType: '',
      },
      tabLoading: false,
      butLoading: false,
      newWealColumns: [
        { type: 'index', label: '序号', width: '60', align: 'center' },
        { label: '充值金额', prop: 'rcgAmt', align: 'left', 'show-overflow-tooltip': true },
        { label: '赠送金额', prop: 'rwrdAmt', align: 'left', 'show-overflow-tooltip': true },
        { label: '转盘次数', prop: 'luckNum', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'fun', label: '活动类型', prop: 'rcgType', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.rcgTypeList.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { label: '开始日期', prop: 'startDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '结束日期', prop: 'endDate', align: 'center', 'show-overflow-tooltip': true },
        { label: '创建时间', prop: 'createDate', align: 'left', width: '170', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '120',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList }
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogTitle: '新增',
      dialogFormVisible: false,
      ruleForm: {
        id: '',
        rcgAmt: '', //充值金额
        rwrdAmt: '', //赠送金额
        luckNum: '',//转盘次数
        rcgType: '', //充值活动类型
        startDate: '', //开始时间
        endDate: '', //结束时间
      },
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/rcgAct/findRcgActList', {
        params: {
          currentPage: 1,
          pageSize: 10
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //新增列表
    addList () {
      this.dialogTitle = "新增"
      this.ruleForm = {
        id: '',
        rcgAmt: '', //充值金额
        rwrdAmt: '', //赠送金额
        rcgType: '', //充值活动类型
        rcgType: '',//转盘次数
        startDate: '', //开始时间
        endDate: '', //结束时间
      }
      this.dialogFormVisible = true
    },
    //编辑
    editList (row) {
      this.dialogTitle = "编辑"
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          let apiUrl = ""
          if (this.dialogTitle == '新增') {
            apiUrl = "/rcgAct/addRcgAct"
          } else if (this.dialogTitle == '编辑') {
            apiUrl = "/rcgAct/editRcgAct"
          }
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
          this.$http.post(apiUrl, ruleForm).then(({ data: result }) => {
            console.log(result.data)
            this.$message({
              message: this.dialogTitle + '成功',
              type: 'success'
            })
            this.getTableData()
            this.butLoading = false
            this.dialogFormVisible = false
          }).catch(err => {
            this.butLoading = false
          })
        }
      })
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/rcgAct/removeRcgActById', { id: row.id }).then(res => {
          this.getTableData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      })
    },
    //跳转详情
    headerRowClick (row) {
      console.log(row)
    },
    //重置清除校验
    resetForm () {
      Object.assign(
        this.$data.ruleForm,
        this.$options.data().ruleForm
      )
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>